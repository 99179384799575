import {useParams} from "react-router";
import {Header} from "../utils/Header";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardOverflow,
    CircularProgress,
    Container,
    Divider, FormControl, FormLabel,
    IconButton, Option, Select,
    Snackbar,
    Stack,
    Typography
} from "@mui/joy";
import {useEffect, useState} from "react";
import "firebase/compat/firestore"
import {
    barberOrNull,
    FUNCTION_ANNULLA_PRENOTAZIONE,
    FUNCTION_GET_PRENOTAZIONE,
    FUNCTION_MODIFICA_SLOT, FUNCTION_PRENOTA_SLOT,
    FUNCTION_SLOT_DISPONIBILI, groupByDate
} from "../utils/General";
import {getFunctions, httpsCallable} from "firebase/functions";
import {Close} from "@mui/icons-material";
import moment from "moment";
import {Link} from "react-router-dom";
import {Services} from "../utils/Services";


export function Booking() {

    let { id } = useParams();
    const [tab, setTab] = useState(0)
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [slotLiberi, setSlotLiberi] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedBarber, setSelectedBarber] = useState("");

    useEffect(() => {
        if(tab===1) {
            const duration = moment.unix(data.datetimeend).diff(moment.unix(data.datetimestart), "minutes")
            getSlotLiberi(
                duration,
                barberOrNull([selectedBarber])
            )
        }
    }, [selectedBarber]);

    useEffect(() => {
        setLoading(true)
        setError(null)
        const functions = getFunctions();
        const callablefunction = httpsCallable(functions, FUNCTION_GET_PRENOTAZIONE);
        callablefunction({id: id})
            .then((res) => {
                switch (res.data.code) {
                    case 200:
                        setError(null)
                        setLoading(false)
                        setData(res.data.prenotazione)
                        setSelectedBarber(res.data.prenotazione.barber)
                        break
                    case 404:
                        setError("Nessuna prenotazione trovata")
                        setLoading(false)
                        break
                    default:
                        setError("Si è verificato un errore")
                        setLoading(false)
                        break
                }
            })
            .catch((err) => {
                setError("Si è verificato un errore")
                setLoading(false)
            })
    }, []);

    return (
        <Box>
            <Header />
            <Container sx={{ display: 'flex', flexDirection:"row", justifyContent:"center", alignItems:"center", minHeight:"90vh" }}>
                {
                    loading ?
                        <CircularProgress />
                        :
                        data!==null ?
                            switchViews()
                            :
                            <Stack sx={{textAlign:"center"}}>
                                <Typography level="h3">
                                    Non abbiamo trovato nessuna prenotazione
                                </Typography>
                                <Typography level="body-sm" sx={{mb:3}}>
                                    Controlla che il codice sia corretto: <span className="span-as-pre">{id}</span>
                                </Typography>
                                <Link to="/">
                                    <Button fullWidth>
                                        Torna indietro
                                    </Button>
                                </Link>
                            </Stack>
                }
            </Container>
            <Snackbar
                autoHideDuration={5000}
                open={error !== null}
                variant="outlined"
                color="danger"
                size="md"
                onClose={() => {
                    setError(null);
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                endDecorator={
                    <IconButton
                        onClick={() => setError(null)}
                        size="sm"
                    >
                        <Close />
                    </IconButton>
                }
            >
                {error}
            </Snackbar>
        </Box>
    )

    function switchViews() {
        switch (tab) {
            // visualizza prenotazione
            case 0:
                return (
                    <Stack direction={{xs:"column", md:"row"}} spacing={5} sx={{ width:"100%", maxWidth:"100vw", display: 'flex', justifyContent:"space-evenly", alignItems:"center" }}>
                        <Stack spacing={3} textAlign={{xs:"center", md:"left"}}>
                            <Typography level="h3">
                                I tuoi dati
                            </Typography>
                            <Stack>
                                <Typography level="body-lg">
                                    Nome: <b>{data.name}</b>
                                </Typography>
                                <Typography level="body-lg">
                                    Cognome: <b>{data.surname}</b>
                                </Typography>
                                <Typography level="body-lg">
                                    Telefono: <b>{data.phone}</b>
                                </Typography>
                                <Typography level="body-lg">
                                    Email: <b>{data.email}</b>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Card
                            size="lg"
                            variant="soft"
                            sx={{ borderRadius: 'xl', minWidth: { xs:"auto", md:"350px" }, }}
                        >
                            <CardContent>
                                <Stack spacing={3}>
                                    <Typography level="h3">La tua prenotazione</Typography>
                                    <Stack>
                                        <Typography level="body">
                                            Data: <b>{moment.unix(data.datetimestart).format("DD/MM/YYYY")}</b>
                                        </Typography>
                                        <Typography level="body">
                                            Ora: <b>{moment.unix(data.datetimestart).format("HH:mm")} - {moment.unix(data.datetimeend).format("HH:mm")}</b>
                                        </Typography>
                                        <Typography level="body">
                                            Barbiere: <b>{data.barber}</b>
                                        </Typography>
                                    </Stack>

                                    {
                                        moment.unix(data.datetimestart).isBefore(moment().add(1, "day").startOf("day")) ?
                                            <Typography level="body-sm" textAlign="center" fontStyle="italic" fontWeight="bold">
                                                La prenotazione non è modificabile
                                            </Typography>
                                            :
                                            <Stack direction={{xs:"column", md:"row"}} spacing={2} sx={{ display: 'flex', justifyContent:"center", alignItems:"center"}}>
                                                <Button
                                                    variant="outlined"
                                                    color="danger"
                                                    fullWidth
                                                    onClick={() => {setTab(2)}}
                                                >
                                                    Annulla prenotazione
                                                </Button>
                                                <Button
                                                    variant="solid"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={() => {
                                                        setTab(1)
                                                        const duration = moment.unix(data.datetimeend).diff(moment.unix(data.datetimestart), "minutes")
                                                        const service = Services.filter(a => a.id === data.service)[0]
                                                        getSlotLiberi(
                                                            duration,
                                                            barberOrNull([data.barber])
                                                        )
                                                    }}
                                                >
                                                    Cambia data/ora
                                                </Button>
                                            </Stack>
                                    }
                                </Stack>
                            </CardContent>
                            <CardOverflow variant="soft" sx={{ bgcolor: 'background.level1' }}>
                                <Divider inset="context" />
                                <CardContent orientation="horizontal" sx={{justifyContent:"end", py:1}}>
                                    <Typography level="body-sm" textAlign="end">
                                        Codice prenotazione: <span className="span-as-pre">{id}</span>
                                    </Typography>
                                </CardContent>
                            </CardOverflow>
                        </Card>
                    </Stack>
                )
            // modifica prenotazione
            case 1:
                return (
                    <Stack spacing={3} sx={{justifyContent:"center", alignItems: "center"}}>
                        {
                            loading ?
                                <CircularProgress/>
                                :
                                slotLiberi !== null ?
                                    <>
                                        <Stack textAlign="center">
                                            <Typography level="h3">Data e ora dell'appuntamento</Typography>
                                            <Typography level="body-sm">Scegli uno slot disponibile</Typography>
                                            <FormControl size="sm">
                                                <FormLabel>Barbiere</FormLabel>
                                                <Select value={selectedBarber}
                                                        onChange={(e, newValue) => setSelectedBarber(newValue)}
                                                >
                                                    <Option value="Andrea">Andrea</Option>
                                                    <Option value="Mirko">Mirko</Option>
                                                </Select>
                                            </FormControl>
                                        </Stack>
                                        <Stack direction="row" spacing={3} sx={{maxWidth: {xs:"95vw", lg:"80vw"}, overflowX:"auto", scrollbarWidth: "thin", scrollbarColor: "lightgray transparent"}}>
                                            {
                                                Object.keys(slotLiberi).map(k =>
                                                    <Stack key={k} spacing={2} sx={{
                                                        minWidth: {
                                                            xs: "calc(100% / 3)",
                                                            md: "calc(100% / 7)",
                                                        }
                                                    }}>
                                                        <Typography level="body-lg" fontWeight="bold" textAlign="center">
                                                            {moment.unix(k).format("ddd DD/MM")}
                                                        </Typography>
                                                        <Stack spacing={1} sx={{maxHeight: {xs:"60vh", md:"50vh"}, pl: 1}}
                                                               className="scrollbarOrari">
                                                            {
                                                                slotLiberi[k].map(s =>
                                                                    <Button key={s.datetimestart}
                                                                            variant={selectedSlot?.datetimestart === s?.datetimestart ? "solid" : "outlined"}
                                                                            size="sm"
                                                                            onClick={() => setSelectedSlot({...s, barber: s.barber[Math.floor(Math.random() * s.barber.length)]})}
                                                                    >
                                                                        {moment.unix(s.datetimestart).format("HH:mm")}
                                                                    </Button>
                                                                )
                                                            }
                                                        </Stack>
                                                    </Stack>
                                                )
                                            }
                                        </Stack>

                                        <Container spacing={2} sx={{
                                            width: "100%",
                                            display: 'flex',
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            mx:3,
                                        }}>
                                            <Button
                                                variant="outlined"
                                                color="neutral"
                                                fullWidth
                                                onClick={() => {
                                                    setTab(0)
                                                    setSelectedSlot(null)
                                                }}
                                                sx={{mr:1}}
                                            >
                                                Indietro
                                            </Button>
                                            <Button
                                                variant="solid"
                                                color="primary"
                                                fullWidth
                                                onClick={() => {
                                                    modificaSlot()
                                                }}
                                                disabled={!selectedSlot}
                                                sx={{ml:1}}
                                            >
                                                Conferma modifica
                                            </Button>
                                        </Container>
                                    </>
                                    :
                                    <Stack sx={{textAlign: "center"}}>
                                        <Typography level="h3">
                                            Si è verificato un problema
                                        </Typography>
                                        <Typography level="body-sm" sx={{mb: 3}}>
                                            Riprova più tardi.
                                        </Typography>
                                        <Button fullWidth onClick={() => {
                                            window.location.reload()
                                        }}>
                                            Ricarica la pagina
                                        </Button>
                                    </Stack>
                        }
                    </Stack>
                )
            case 2:
                return (
                    <Stack spacing={5} sx={{ width:"100%", textAlign: "center" }}>
                        <Stack spacing={3}>
                            <Stack>
                                <Typography level="h3">
                                    Annullare definitivamente la prenotazione?
                                </Typography>
                                <Typography level="body-sm">
                                    Se annulli la prenotazione, qualcun altro potrà prenotare il tuo turno.
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent:"center", alignItems:"center"}}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {setTab(0)}}
                                >
                                    Indietro
                                </Button>
                                <Button
                                    variant="solid"
                                    color="danger"
                                    onClick={() => {
                                        annullaPrenotazione(id)
                                    }}
                                    disabled={loading}
                                >
                                    Annulla prenotazione
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                )
            // conferma modifica prenotazione
            case 3:
                return (
                    <Stack sx={{textAlign:"center"}}>
                        <Typography level="h3">
                            La tua prenotazione è stata modificata
                        </Typography>
                        <Typography level="body-sm" sx={{mb:3}}>
                            Riceverai una mail e un messaggio di conferma.
                        </Typography>
                        <Button fullWidth onClick={() => {window.location.reload()}}>
                            Gestisci la prenotazione
                        </Button>
                    </Stack>
                )
            case 4:
                return (
                    <Stack sx={{textAlign:"center"}}>
                        <Typography level="h3">
                            La tua prenotazione è stata annullata
                        </Typography>
                        <Typography level="body-sm" sx={{mb:3}}>
                            Riceverai una mail e un messaggio di conferma.
                        </Typography>
                        <Link to="/prenota">
                            <Button fullWidth>
                                Prenota un nuovo appuntamento
                            </Button>
                        </Link>
                    </Stack>
                )
            // errore generico
            default:
                return (
                    <Stack sx={{textAlign:"center"}}>
                        <Typography level="h3">
                            Si è verificato un problema
                        </Typography>
                        <Typography level="body-sm" sx={{mb:3}}>
                            Riprova più tardi.
                        </Typography>
                        <Button fullWidth onClick={() => {window.location.reload()}}>
                            Ricarica la pagina
                        </Button>
                    </Stack>
                )
        }
    }

    function annullaPrenotazione(id) {
        setLoading(true)
        const functions = getFunctions();
        const callablefunction = httpsCallable(functions, FUNCTION_ANNULLA_PRENOTAZIONE);
        callablefunction({ id: id })
            .then((res) => {
                setTab(4)
                setLoading(false)
            })
            .catch((err) => {
                setError("Si è verificato un errore")
                setLoading(false)
            })
    }

    function modificaSlot() {
        setLoading(true)
        const duration = moment.unix(selectedSlot.datetimeend).diff(moment.unix(selectedSlot.datetimestart), "minutes")
        const functions = getFunctions();
        const callablefunction = httpsCallable(functions, FUNCTION_MODIFICA_SLOT);
        callablefunction({
            id: id,
            datetimestart: selectedSlot.datetimestart,
            datetimeend: moment.unix(selectedSlot.datetimestart).add(duration, "minutes").unix(),
            barber: selectedSlot.barber
        })
            .then((res) => {
                switch (res.data.code) {
                    case 200:
                        setTab(3)
                        setLoading(false)
                        break
                    case 404:
                        setError("Il turno non è più disponibile")
                        setLoading(false)
                        break
                    case 409:
                        setError("Qualcuno ha prenotato prima di te")
                        setLoading(false)
                        break
                    default:
                        setError("Si è verificato un errore")
                        setLoading(false)
                        break
                }
            })
            .catch((err) => {
                setError("Si è verificato un errore")
            })
    }

    function getSlotLiberi(duration, barber) {
        setLoading(true)
        const functions = getFunctions();
        const callablefunction = httpsCallable(functions, FUNCTION_SLOT_DISPONIBILI);
        callablefunction({duration: duration, barber: barber})
            .then((res) => {
                switch (res.data.code) {
                    case 200:
                        const groupedEvents = groupByDate(res.data.slots.filter(a => a.datetimestart !== data.datetimestart));
                        //console.log(groupedEvents)
                        //Object.keys(groupedEvents).map(d => groupedEvents[d].map(o => console.log(moment.unix(o.datetimestart).format("DD/MM/YY HH:mm"), moment.unix(o.datetimeend).format("DD/MM/YY HH:mm"), o.barber.toString())))
                        setSlotLiberi(groupedEvents)
                        setLoading(false)
                        break
                    case 404:
                        setError("Nessun turno disponibile per il barbiere specificato")
                        setLoading(false)
                        break
                    default:
                        setError("Si è verificato un errore")
                        //console.log(res)
                        setLoading(false)
                        break
                }
            })
            .catch((err) => {
                setError("Si è verificato un errore")
                //console.log(err)
                setLoading(false)
            })
    }
}