import moment from "moment/moment";


export const DB_PRENOTAZIONI = "bookings"
export const DB_TURNI = "shifts"
export const FUNCTION_SLOT_DISPONIBILI = "getSlotDisponibili"
export const FUNCTION_PRENOTA_SLOT = "prenotaSlot"
export const FUNCTION_ANNULLA_PRENOTAZIONE = "annullaPrenotazione"
export const FUNCTION_MODIFICA_SLOT = "modificaSlot"
export const FUNCTION_GET_PRENOTAZIONE = "getPrenotazione"
export const FUNCTION_GET_STATS = "getStats"
export const STATUS_PENDING = "PENDING"
export const STATUS_CONFIRMED = "CONFIRMED"
export const STATUS_REJECTED = "REJECTED"
export const STATUS_DELETED = "DELETED"
export const LOGO = "https://www.scalpsquadbarberia.it/wp-content/uploads/2024/06/13.png"
export const LOGO_SCRITTA = "https://www.scalpsquadbarberia.it/wp-content/uploads/2024/06/9.png"


export function isValidPhoneNumber(phoneNumber) {
    const phoneRegex = /^(\+?\d{1,3})?\s?\d{6,14}$/
    return phoneRegex.test(phoneNumber);
}

export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function groupByDate(appointments) {
    return appointments.reduce((acc, appointment) => {
        const date = moment.unix(appointment.datetimestart).startOf("day").unix();
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(appointment);
        return acc;
    }, {});
}

export function barberOrNull(barbers) {
    if (barbers.length > 1) return null
    else return barbers[0]
}